const days = [
  "01 perfect loop",
  "02 10 minutes",
  "03 glitch art",
  "04 intersections",
  "05 debug view",
  "06 steal like an artist",
  "07 sample a color palette from your favorite movie/album cover",
  "08 signed distance functions",
  "09 plants",
  "10 Generative music",
  // "11 Suprematism",
  "12 tessellation",
  // "13 Something you’ve always wanted to learn",
  // "14 Aesemic",
  "15 sine waves",
  "16 reflection of a reflection",
  "17 a grid inside a grid inside a grid",
  // "18 Definitely not a grid",
  // "19 Black and white",
  // "20 Art Deco",
  // "21 Persian Rug",
  // "22 Shadows",
  // "23 More Moiré",
  // "24 Textile",
  // "25 Yayoi Kusama",
  // "26 My kid could have made that",
  // "27 In the style of Hilma Af Klint",
  // "28 Generative poetry",
  // "29 Maximalism",
  // "30 Minimalism",
  // "31 Deliberately break one of your previous images, take one of your previous works and ruin it.",
];
document.querySelector(".header").style.backgroundColor = "#000";

let day = new Date();
let str = "";
days.forEach((s, i) => {
  // if (i > day.getDay()) return;

  const a = s.split(" ");
  const n = a.shift();
  const t = a.join(" ");
  console.log(n);
  str += `
  <div class="entry" style="background-image: url(./assets/covers/${n}.jpg)" >
      <a href="${n}/index.html">
      <div class="item" >
        <div class="number">${n}</div>
        <div class="text">${t}</div>
      </div>
      </a>
  </div>
    `;
});

const grid = document.querySelector(".grid");
grid.innerHTML = str;
const entries = document.querySelectorAll(".item");

let id = 0;
for (let o of entries) {
  let color = `hsla(${~~(Math.random() * 180)}, 60%, 60%, .65 )`;

  o.style.backgroundColor = "rgba(0,0,0,0.85)";
  //   o.innerText += " " + colors[0];
}

// this is a(broken yet explanatory) version of the GOL.
// each update determines if a cell is alive or dead, then I collect all the living cells and stack them to create the geometry.
